<template>
  <div id="PasswordReset">
    <!-- <TheHeader></TheHeader>
    <SiteNav v-if="user"></SiteNav> -->
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-lines bg-cover bg-center">
    <div class="max-w-md w-full space-y-8">
      <img class="mx-auto h-28 w-auto" src="@/assets/logo_border.png" alt="Workflow" />
      <div class="bg-gray-800 rounded-lg mt-6 pb-3 p-3">
        <div class="text-shadow">
          <h2 class="mt-4 text-center text-3xl font-extrabold text-white">
            Passwort vergessen?
          </h2>
          <p class="mt-2 text-center  text-gray-300">
            Gebe Deine E-Mail Adresse ein. Wir senden Dir eine E-Mail zu, mit der Du wieder Zugriff auf Dein Konto erhältst.
          </p>
          <p class="text-center"><a class="link text-sm" href="https://hilfe.stadtbahnfahrer.club" target="_newTab">Benötigst Du Hilfe? Kontaktiere uns gerne.</a></p>
        </div>
        <form v-on:submit.prevent v-show="!isSuccess && isFailed == 0" class="mt-8 space-y-6" action="#" method="POST">
          <div class="rounded-md shadow-sm -space-y-px text-center">
            <div>
              <h4 class="mt-6  font-extrabold text-white text-shadow">
                Deine E-Mail Adresse
              </h4>
              <label for="email-address" class="sr-only">Deine E-Mail Adresse</label>
              <input @input="checkInputs" id="email-address" name="email" type="email" autocomplete="email" required v-model.trim="email" :disabled="isLoading" class="text-center appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="E-Mail Adresse" />
            </div>
          </div>

          <div class="flex mt-3 justify-center">
            <vue-hcaptcha sitekey="3e251ea3-6db0-4aa1-b668-ae4d255f2a3e" @verify="captchaOnVerify" @expired="captchaOnExpired" @error="captchaOnError"></vue-hcaptcha>
          </div>

          <div>
            <button :disabled="isLoading || stepCompleteDisabled" @click="submit" type="submit" class="button-filled group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white hover:bg-blue-400">
              Neues Passwort anfordern
            </button>
          </div>

          <div class="flex items-center justify-center">
            <a href="/anmelden" class="text-center text-sm font-medium text-blue-600 hover:text-blue-500 text-shadow">
              Zur Anmeldung
            </a>
          </div>
        </form>
        <div v-show="isSuccess">
          <p class="text-center mt-3">Wenn ein Konto mit der E-Mail Adresse <i>{{email}}</i> bei uns existiert, werden wir eine E-Mail an diese versenden. Prüfe ggf. auch Deinen Spam-Ordner.</p>
          <button @click="isFailed = 0; isSuccess = false" class="button-filled my-4 w-full">Zurück</button>
        </div>
        <div v-show="!isSuccess && isFailed != 0">
          <p class="text-center mt-3">Ungültige E-Mail Adresse oder Captcha nicht korrekt gelöst. Versuche es bitte erneut.</p>
          <button @click="isFailed = 0" class="button-filled my-4 w-full">Zurück</button>
        </div>
      </div>
    </div>
  </div>
  </div> 
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: "Login",
  components: {
    TheHeader,
    SiteNav,
    VueHcaptcha
  },
  data() {
    return {
      stepCompleteDisabled: true,
      isLoading: false,
      isSuccess: false,
      email: "",
      isCaptchaComplete: false,
      captchaData: {
        token: null,
        eKey: null
      },
      isFailed: 0
    };
  },
  methods: {
    captchaOnVerify(token, eKey) {
      // console.log("captchaOnVerify()");
      // console.log(token);
      this.isCaptchaComplete = true
      this.captchaData = {
        token: token,
        eKey: eKey
      }
    },
    captchaOnExpired() {
      // console.log("captchaOnExpired()");
      this.isCaptchaComplete = false
    },
    captchaOnError() {
      // console.log("captchaOnError()");
      this.isCaptchaComplete = false
    },
    checkInputs() {
      if(
      this.email.length > 0) {
        this.stepCompleteDisabled = false
      } else {
        this.stepCompleteDisabled = true
      }
    },
    submit() {
      this.isLoading = true;

      this.$store
        .dispatch("requestPasswordReset", {email: this.email, token: this.captchaData.token})
        .then((a) => {
          this.stepCompleteDisabled = true

          this.isLoading = false;

          if (a.status == true) {
            if(a.response.status == 200) {
              this.isSuccess = true;
              return;
            }

            this.isFailed = a.response.status
          } else {
            
            this.isFailed = a.error
          }
            this.email = "";
            console.error("error:submit");
            
        });
    },
  },
  // mounted() {
  //   this.$store.dispatch("fetchUserProfile");
  // },
  // computed: {
  //   ...mapGetters({
  //     user: "getUser",
  //   }),
  // },
};
</script>

<style scoped>
.badge {
  width: 220px;
  margin: auto;
}

.lol {
  max-width: 400px;
}
</style>